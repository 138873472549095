<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
    <g id="Icons/search">
      <path id="Vector"
            d="M6.41667 11.0833C8.994 11.0833 11.0833 8.994 11.0833 6.41667C11.0833 3.83934 8.994 1.75 6.41667 1.75C3.83934 1.75 1.75 3.83934 1.75 6.41667C1.75 8.994 3.83934 11.0833 6.41667 11.0833Z"
            stroke="#A4A4A4" stroke-linecap="round" stroke-linejoin="round"/>
      <path id="Vector_2" d="M12.2504 12.2499L9.71289 9.7124" stroke="#A4A4A4" stroke-linecap="round"
            stroke-linejoin="round"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon'
}
</script>
